<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" custom-class="top5" top="5vh" width="700px">
    <el-upload
      class="upload-document"
      ref="upload"
      drag
      :action="actionUrl"
      multiple
      :file-list="fileList"
      :auto-upload="false"
      :on-success="handleSuccess"
      :on-error="handleError"
      :before-upload="beforeUpload"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text margin-bottom-mini">将文件拖到此处，或<em>点击上传</em></div>
      <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
    </el-upload>
    <div slot="footer" class="dialog-footer">
      <p class="fl" v-if="dataInfo.foldName">
        注：选中文档将直接上传至目录 <span style="color:blue;font-weight:bolder;">{{ dataInfo.foldName }}</span>
      </p>
      <el-button @click="_cancle">关 闭</el-button>
      <el-button type="success" @click="save">上传到服务器</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { dealResponse } from '@/lgh/utils/ajax'
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      documentInfo: null,
      fileList: [],
      actionUrl: process.env.VUE_APP_API_URL + 'api/file/upload'
    }
  },
  methods: {
    save() {
      this.$refs.upload.submit()
    },
    handleSuccess(res, file) {
      dealResponse(res, data => {
        this.uploadDocument(data)
      })
    },
    handleError(res, file) {
      this.$lgh.msgError(res)
    },
    beforeUpload(file) {
      return true
    },
    uploadDocument(file) {
      var data = Object.assign({}, this.documentInfo)
      data.documentName = file.fileName
      data.foldId = this.dataInfo.foldId
      data.fileInfo = {
        fileName: file.fileName,
        name: file.fileName,
        size: file.size,
        extension: file.extension,
        fileUrl: file.url
      }
      this.$lgh.post('api/enterprise/document/add', data, { loading: false }).then(res => {
        this.$lgh.notifySuccess('上传完成', data.documentName)
        this.refreshHandle && this.refreshHandle()
      })
    }
  }
}
</script>

<style lang="less">
.upload-document {
  width: 360px;
  margin: 50px auto;
  .el-upload {
    margin: auto;
  }
}
</style>
