<template>
  <section class="l-content">
    <el-card>
      <div class="el-breadcrumb" v-if="currentFold">
        <span class="el-breadcrumb__item"><span role="link" class="el-breadcrumb__inner is-link" @click="getDataList()">首页</span><i class="el-breadcrumb__separator el-icon-arrow-right"></i></span>
        <span class="el-breadcrumb__item" v-for="(item, index) in currentFold.foldList" :key="index" @click="getDataList(item.foldId)"
          ><span class="el-breadcrumb__inner is-link">{{ item.foldName }}</span
          ><i class="el-breadcrumb__separator el-icon-arrow-right"></i
        ></span>
        <span class="el-breadcrumb__item" v-if="currentFold.foldName"
          ><span class="el-breadcrumb__inner">{{ currentFold.foldName }}</span></span
        >
      </div>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="dataList">
      <el-table :data="dataList" style="width: 100%" :height="height">
        <el-table-column label="文档名称">
          <template slot-scope="scope">
            <div class="flex align-center">
              <el-image style="width: 25px; height: 25px; margin-right: 10px" :src="scope.row.iconUrl"></el-image>
              <el-button v-if="scope.row.isFold" type="text" @click="getDataList(scope.row.foldId)">{{ scope.row.documentName }}</el-button>
              <el-button v-else type="text" @click="$site.view_file(scope.row.fileInfo)">{{ scope.row.documentName }}</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="文档大小" align="center">
          <template slot-scope="scope">
            <div v-if="!scope.row.isFold">
              {{ scope.row.fileInfo.sizeDesc }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="uploadUserName" label="上传人" align="center"> </el-table-column>
        <el-table-column prop="uploadTime" label="上传时间" align="center">
          <template slot-scope="scope">
            <div v-if="!scope.row.isFold">
              {{ scope.row.uploadTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot="header">
            <el-button size="mini" type="primary" @click="createFold()">新建目录</el-button>
            <el-button size="mini" type="" @click="upload()">上传文档</el-button>
          </template>
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="editName(scope.row)">修改名称</el-button>
            <el-button size="mini" type="danger" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import documentUpload from './upload'

export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'DocumentList',
  data() {
    return {
      height: document.documentElement.clientHeight - 200 + 'px',
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 - 50 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '文档名称', prop: 'documentName' },
          { label: '文档大小', prop: 'proposalType' },
          { label: '上传人', prop: 'uploadUserName' },
          { label: '上传时间', prop: 'uploadTime' },
          { label: '操作', slot: 'proposalStatusName' }
        ]
      },
      dataList: null,
      currentFoldId: null,
      currentFold: null
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    async getDataList(foldId) {
      var res = await this.$lgh.get('api/enterprise/document_fold', ['enterpriseCode', { foldId: foldId }])
      this.currentFold = res.fold ? res.fold : { foldList: [] }
      this.currentFoldId = res.fold ? res.fold.foldId : null
      this.dataList = res.list
    },
    async upload() {
      await this.$lgh.openDialog({
        ins: documentUpload,
        title: '文档上传',
        data: {
          dataInfo: Object.assign({}, this.currentFold),
          documentInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            uploadUserId: this.userInfo.userId,
            uploadUserName: this.userInfo.fullName
          },
          refreshHandle: () => {
            this.getDataList(this.currentFoldId)
          }
        },
        onclosed: () => {
          this.getDataList(this.currentFoldId)
        }
      })
      this.$lgh.toastSuccess('上传完成')
      this.getDataList(this.currentFoldId)
    },
    createFold() {
      this.$lgh.msgPrompt('输入新建目录的名称', (data) => {
        if (!data) return
        this.$lgh
          .post('api/enterprise/fold/add', {
            enterpriseCode: this.userInfo.enterpriseCode,
            parentFoldId: this.currentFoldId,
            foldName: data,
            createUserId: this.userInfo.userId,
            createUserName: this.userInfo.fullName
          })
          .then((res) => {
            this.getDataList(this.currentFoldId)
          })
      })
    },
    editName(item) {
      this.$lgh.msgPrompt(
        '修改名称',
        (data) => {
          if (!data) return
          if (item.isFold) {
            this.editFoldName({
              foldId: item.foldId,
              enterpriseCode: item.enterpriseCode,
              foldName: data
            })
          } else {
            this.editDocumentName({
              documentId: item.documentId,
              enterpriseCode: item.enterpriseCode,
              documentName: data
            })
          }
        },
        {
          inputValue: item.documentName
        }
      )
    },
    editFoldName(data) {
      this.$lgh.post('api/enterprise/fold/updatename', data).then((res) => {
        this.getDataList(this.currentFoldId)
      })
    },
    editDocumentName(data) {
      this.$lgh.post('api/enterprise/document/updatename', data).then((res) => {
        this.getDataList(this.currentFoldId)
      })
    },
    async del(item) {
      await this.$lgh.get('api/enterprise/document/delete', {
        documentId: item.documentId
      })
      this.$lgh.notifySuccess('删除成功', item.documentName + '信息已删除')
      this.getDataList(this.currentFoldId)
    }
  }
}
</script>
